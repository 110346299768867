import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

/**
 * Custom Axis extension to allow emulation of negative values on a logarithmic
 * color axis. Note that the scale is not mathematically correct, as a true
 * logarithmic axis never reaches or crosses zero.
 */
(function main(H) {
  H.addEvent(H.Axis, 'afterInit', function afterInitHandler() {
    const { logarithmic } = this;

    if (logarithmic && this.options.allowNegativeLog) {
      // Avoid errors on negative numbers on a log axis
      this.positiveValuesOnly = false;

      // Override the converter functions
      logarithmic.log2lin = (num) => {
        const isNegative = num < 0;

        let adjustedNum = Math.abs(num);

        if (adjustedNum < 10) {
          adjustedNum += (10 - adjustedNum) / 10;
        }

        const result = Math.log(adjustedNum) / Math.LN10;
        return isNegative ? -result : result;
      };

      logarithmic.lin2log = (num) => {
        const isNegative = num < 0;

        let result = 10 ** Math.abs(num);
        if (result < 10) {
          result = (10 * (result - 1)) / (10 - 1);
        }
        return isNegative ? -result : result;
      };
    }
  });
}(Highcharts));

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      column: {
        borderRadius: 3,
      },
      series: {
        animation: false,
        allowPointSelect: false,
        cursor: 'default',
        dataLabels: {
          enabled: false,
        },
      },
    },
    xAxis: {
      type: 'datetime',
      startOnTick: true,
      endOnTick: true,
      gridLineWidth: 0,
      labels: {
        align: 'right',
        style: {
          font: 'normal 9px Verdana, sans-serif',
        },
        maxStaggerLines: 1,
      },
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
    },
    legend: {
      enabled: false, // useless for histogram
    },
    chart: {
      type: 'column',
      defaultSeriesType: 'column',
      zoomType: 'x',
      style: {
        fontFamily: '"Open Sans", Verdana, Arial, Helvetica, sans-serif',
      },
    },
  },
};

const ColumnHisto = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

ColumnHisto.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

export default ColumnHisto;
