import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
} from '@mui/material';
import Highcharts from 'highcharts';
import THEME_CONFIG from 'generic/core/theme';

Highcharts.setOptions(THEME_CONFIG.HIGHCHARTS);

const DashboardWidget = ({
  height, highchartsOptions, handleExportWidget, componentProps, component: Component, flat,
}) => {
  const { t } = useTranslation();
  const menuItems = [
    'viewFullscreen', 'printChart', 'separator',
    'downloadJPEG', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator',
    'downloadXLS', 'downloadCSV',
  ];
  if (handleExportWidget) {
    menuItems.push('downloadAllCSV');
  }
  const globalHighChartsOptions = {
    options: {
      accessibility: { enabled: false },
      credits: { enabled: false },
      exporting: {
        menuItemDefinitions: {
          downloadAllCSV: {
            onclick: handleExportWidget,
            text: t('dashboard.download_all_csv'),
          },
        },
        buttons: {
          contextButton: {
            menuItems,
          },
        },
      },
    },
  };
  const finalOptions = merge({}, globalHighChartsOptions, highchartsOptions);

  return (
    <Box>
      {flat ? (
        <Box>
          {Component == null
            ? ''
            : (
              <Component
                highchartsOptions={finalOptions}
                handleExportWidget={handleExportWidget}
                {...componentProps}
              />
            )}
        </Box>
      ) : (
        <Card>
          <Box
            position="relative"
            ml={0}
            // Attention, pour tout changement de height ou de p et pb ici, il faut
            // également adapter WidgetContainer pour que le Skeleton fasse la bonne
            // hauteur
            height={height}
            p={2}
            pb={3}
          >
            {Component == null
              ? ''
              : (
                <Component
                  highchartsOptions={finalOptions}
                  handleExportWidget={handleExportWidget}
                  {...componentProps}
                />
              )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

DashboardWidget.propTypes = {
  height: PropTypes.number,
  component: PropTypes.elementType,
  componentProps: PropTypes.shape(),
  highchartsOptions: PropTypes.shape(),
  flat: PropTypes.bool,
  handleExportWidget: PropTypes.func,
};

DashboardWidget.defaultProps = {
  height: THEME_CONFIG.HIGHCHARTS.chartsDefaultHeight,
  component: null,
  highchartsOptions: {},
  componentProps: {},
  flat: false,
  handleExportWidget: null,
};

export default DashboardWidget;
