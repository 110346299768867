import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { cleanupResults, refreshResults } from 'generic/core/search/actions';
import { cleanupDashboard } from 'generic/core/dashboard/actions';

import DashboardHeaderContainer from 'generic/containers/DashboardHeaderContainer';
import GEDModalContainer from 'generic/containers/GEDModalContainer';
import RelationsChartsContainer from 'custom/smartcti/containers/RelationsChartsContainer';
import QuickResultsContainer from 'generic/containers/QuickResultsContainer';
import DashboardBottomHelp from 'generic/components/ui/DashboardBottomHelp';

const RelationsDashboardWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => (
    // Cleanup des widgets lorsque le composant est "unmount" (donc quand on
    // change de page)
    () => {
      if ((!history?.location?.pathname?.startsWith('/ged')
        && !history?.location?.pathname?.startsWith('/search/results'))
        || history.action === 'POP') {
        // Si jamais on sort de l'univers "resultats", on vire
        // les résultats et la stratégie également, pour que le refresh
        // suivant du dashboard soit propre.
        dispatch(cleanupResults());
      }
      dispatch(cleanupDashboard());
    }
  ), [dispatch, history]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="inherit"
      className="desktopOnlyOverflow"
    >
      <QuickResultsContainer />
      <DashboardHeaderContainer />
      <RelationsChartsContainer />
      <DashboardBottomHelp />

      <GEDModalContainer
        afterSave={() => dispatch(refreshResults())}
      />
    </Box>
  );
};

export default RelationsDashboardWrapper;
