export default {
  DEFAULT_SEARCH_PER_BASE: {
    22801208: {
      DATE_10000029_comp: 1,
      DATE_10000029_from: '01/01/2100',
    },
  },
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,
};
