import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import AccountMenu from 'generic/components/ui/AccountMenu';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { overrideRessource } from 'generic/utils/utils';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';
import { useSelector } from 'react-redux';
import HeaderMenuItem from 'generic/components/ui/HeaderMenuItem';

const logo = overrideRessource('img/header-logo.svg', true);

const useStyles = makeStyles((theme) => ({
  logo: theme.custom.logo,
}));

const LinksMenu = ({ mobile }) => {
  const defaultBaseId = useSelector((state) => state.config.settings.baseInterne_368);
  const baseId = useSelector((state) => state.config.activeBase.base);
  const { t } = useTranslation();
  const location = useLocation();

  const Item = mobile ? MenuItem : HeaderMenuItem;
  return (
    <Fragment>
      <Item
        component={NavLink}
        to="/anr/folders"
        selected={location.pathname.startsWith('/anr/folders')}
      >
        {t('header.web_tracking')}
      </Item>
      <Item
        component={NavLink}
        to={`/search/dashboard/${defaultBaseId}`}
        selected={location.pathname === `/search/dashboard/${defaultBaseId}`}
      >
        {t('header.explore')}
      </Item>
      <Item
        component={NavLink}
        to="/search/dashboard/22801208"
        selected={location.pathname === '/search/dashboard/22801208'}
      >
        {t('header.relations')}
      </Item>
      <Item
        component={NavLink}
        to={{ pathname: 'https://enrichdev.qwamci.com/' }}
        target="_blank"
      >
        Enrich
      </Item>
      {mobile && (
        <Fragment>
          <Divider />
          <Item
            component={NavLink}
            to={`/search/dashboard/${baseId}${location?.search || ''}`}
            selected={location.pathname.startsWith('/search/dashboard')}
          >
            {t('header.dashboard')}
          </Item>
          <Item
            component={NavLink}
            to={`/search/results/${baseId}${location?.search || ''}`}
            selected={location.pathname.startsWith('/search/results')}
          >
            {t('header.results')}
          </Item>
        </Fragment>
      )}
    </Fragment>
  );
};

LinksMenu.propTypes = {
  mobile: PropTypes.bool,
};

LinksMenu.defaultProps = {
  mobile: false,
};

const Header = ({
  user,
  hasCarts,
  hasWatches,
}) => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;
  const toolbarPadding = smallerThanLarge ? '10px' : '30px';

  return (
    <ClickAwayListener
      onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
      mouseEvent="onMouseDown"
    >
      <Box width="100%">
        <AppBar elevation={0} position="static">
          <Toolbar
            className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            sx={{ pl: toolbarPadding, pr: toolbarPadding }}
          >
            <Fragment>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <img
                  src={logo}
                  className={classes.logo}
                  alt="Qwam Logo"
                  style={{ display: forceHideMenusForMobile ? 'none' : 'block' }}
                />
                <DropdownMenu
                  btnChildren={<Menu />}
                  btnProps={{
                    sx: {
                      display: forceShowMenusForMobile ? 'flex' : 'none',
                      padding: '8px',
                      color: '#ffffff',
                    },
                  }}
                >
                  <LinksMenu
                    mobile
                    hasWatches={hasWatches}
                    hasCarts={hasCarts}
                  />
                </DropdownMenu>
                {!smallerThanLarge && (
                  <LinksMenu
                    hasWatches={hasWatches}
                    hasCarts={hasCarts}
                  />
                )}
              </Box>
              <HeaderSearchWrapper showSearchInput={isSearchInputVisible} />
              <Box display="flex" alignItems="center">
                <ToggleMobileSearchIconContainer
                  handleClick={() => setIsSearchInputVisible(true)}
                  isVisible={forceShowMenusForMobile}
                />
                <Box
                  sx={{
                    display: forceHideMenusForMobile ? 'none' : 'inline-block',
                  }}
                >
                  { user && (
                    <AccountMenu
                      user={user}
                      hasWatches={hasWatches}
                      hasCarts={hasCarts}
                      webTrackingAccess={user.accesAnR}
                    />
                  )}
                </Box>
              </Box>
            </Fragment>
          </Toolbar>
        </AppBar>
      </Box>
    </ClickAwayListener>
  );
};

Header.propTypes = {
  user: utilisateurPropType,
  hasWatches: PropTypes.bool,
  hasCarts: PropTypes.bool,
};

Header.defaultProps = {
  user: undefined,
  hasWatches: false,
  hasCarts: false,
};

export default Header;
