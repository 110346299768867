import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import THEME_CONFIG from 'generic/core/theme';
import { overrideRessource } from 'generic/utils/utils';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';

const DocumentForWidgetList = overrideRessource('components/documents/DocumentForWidgetList');

const DocumentList = ({
  title,
  qesdocuments,
  handleDocumentClick,
  handleOpenFastGed,
  groupField,
  fullHeight,
}) => {
  const { t } = useTranslation();

  let finalDocs = qesdocuments;
  if (groupField) {
    finalDocs = _.reduce(qesdocuments, (acc, curr) => {
      const groupName = curr[groupField];
      if (_.isEmpty(acc) || (_.last(acc).groupName !== groupName)) {
        const header = {
          label: groupName,
          isHeader: true,
          nbDocs: _.filter(qesdocuments, { [groupField]: groupName }).length,
        };
        acc.push(header);
      }
      acc.push({
        ...curr,
        groupName,
      });
      return acc;
    }, []);
  }

  const stickyProps = {
    position: 'sticky',
    zIndex: 1200,
    backgroundColor: '#ffffff',
    marginBottom: '1em',
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!_.isEmpty(title.text) && (
        <Typography
          component="div"
          sx={{
            ...stickyProps,
            ...THEME_CONFIG.HIGHCHARTS.title.style,
            top: 0,
            height: '35px',
            paddingTop: '5px',
            paddingLeft: '15px',
            textAlign: title.align,
          }}
        >
          {title.text}
        </Typography>
      )}

      <List dense sx={{ padding: '0', overflowY: fullHeight ? 'unset' : 'auto' }}>
        {_.isEmpty(qesdocuments) && (
          <ListItemText
            primaryTypographyProps={{ textAlign: 'center' }}
          >
            {t('results.no_results')}
          </ListItemText>
        )}
        {!_.isEmpty(qesdocuments) && (
          finalDocs.map(
            (item) => {
              if (item?.isHeader) {
                return (
                  <ListItem
                    key={item.label}
                    alignItems="flex-start"
                    dense
                    sx={{
                      ...stickyProps,
                      top: '35px',
                      borderBottom: '1px solid #aaa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: '15px',
                    }}
                  >
                    <Tooltip title={item.label}>
                      <Typography noWrap width="75%">{item.label}</Typography>
                    </Tooltip>
                    {item.nbDocs}
                  </ListItem>
                );
              }
              return (
                <ListItem
                  key={item?.idext}
                  alignItems="flex-start"
                >
                  <DocumentForWidgetList
                    qesdocument={item}
                    handleDocumentClick={() => handleDocumentClick(item.idext)}
                    openFastGed={() => handleOpenFastGed(item.idext, item.base)}
                  />
                </ListItem>
              );
            },
          )
        )}
      </List>
    </Box>
  );
};

DocumentList.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent']),
  }),
  qesdocuments: PropTypes.arrayOf(qesdocumentPropType).isRequired,
  handleDocumentClick: PropTypes.func,
  handleOpenFastGed: PropTypes.func,
  groupField: PropTypes.string,
  fullHeight: PropTypes.bool,
};

DocumentList.defaultProps = {
  title: {
    text: '',
    align: 'center',
  },
  handleDocumentClick: _.noop,
  handleOpenFastGed: _.noop,
  groupField: null,
  fullHeight: false,
};

export default DocumentList;
