import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: { type: 'pie' },
    credits: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
      },
      pie: {
        borderWidth: 0,
        showInLegend: false,
        size: '75%',
        innerSize: '60%',
        cursor: 'pointer',
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y} ({point.percentage:.1f}%)</b>',
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          plotOptions: {
            series: {
              animation: false,
            },
            pie: {
              size: '60%',
            },
          },
        },
      }],
    },
    series: [{
      name: 'Data',
      dataLabels: {
        enabled: true,
        connectorColor: 'grey',
        color: 'black',
        connectorWidth: 1,
        distance: 20,
        softConnector: false,
        formatter() {
          if (this.point.name.length > 20) {
            return `${this.point.name.substring(0, 20)}...`;
          }
          return this.point.name;
        },
      },
    }],
    title: { text: 'Titre Pie' },
  },
};

const Pie = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Pie.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Pie.defaultProps = {
};

export default Pie;
