/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import THEME_CONFIG from 'generic/core/theme';
import _ from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';

const Datatable = ({
  columns,
  data,
  handleExportWidget,
  onRowClick,
  title,
}) => {
  const TableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead,
    TableRow: (props) => <TableRow hover={onRowClick !== null} {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  const { t } = useTranslation();
  return (
    <Fragment>
      <Box display="flex">
        <Typography
          component="div"
          sx={{
            ...THEME_CONFIG.HIGHCHARTS.title.style,
            height: '35px',
            flexGrow: '1',
            paddingTop: '5px',
            paddingLeft: '15px',
            textAlign: title.align || 'center',
          }}
        >
          {title.text}
        </Typography>
        {handleExportWidget !== null && (
          <TooltipButton
            title={t('dashboard.download_all_csv')}
            tag="iconButton"
            onClick={handleExportWidget}
          >
            <Download />
          </TooltipButton>
        )}
      </Box>
      <TableVirtuoso
        style={{ height: 'calc(100% - 40px)', boxShadow: 'none' }}
        data={data}
        components={TableComponents}
        fixedHeaderContent={() => (
          <TableRow sx={{ backgroundColor: '#ffffff' }}>
            {_.map(columns, (column) => (
              <TableCell
                sx={{ py: '10px' }}
                key={column.name}
                component="th"
                align={column.align || 'left'}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(i, row) => (
          _.map(
            row,
            (rowValue, index) => {
              let tableCellSx = {};
              if (onRowClick !== null) {
                tableCellSx = { cursor: 'pointer' };
                if (index === 0) {
                  tableCellSx.width = '75%';
                }
              }
              return (
                <TableCell
                  key={rowValue}
                  align={columns[index].align || 'left'}
                  onClick={onRowClick ? (event) => onRowClick(event, row) : _.noop}
                  sx={tableCellSx}
                >
                  {columns[index].cellRenderer ? columns[index].cellRenderer(rowValue) : rowValue}
                </TableCell>
              );
            },
          )
        )}
      />
    </Fragment>
  );
};

Datatable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string,
      cellRenderer: PropTypes.func,
      name: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  handleExportWidget: PropTypes.func,
  onRowClick: PropTypes.func,
  title: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent']),
  }),
};

Datatable.defaultProps = {
  data: [],
  handleExportWidget: null,
  onRowClick: null,
  title: {
    text: '',
    align: 'center',
  },
};

export default Datatable;
