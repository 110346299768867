import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DashboardBottomHelp = () => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  if (smallerThanLarge) {
    return null;
  }
  return (
    <Box
      textAlign="center"
      p="6px"
      borderTop="1px solid #c5c5c5"
      fontSize="16px"
      color="#777777"
      fontStyle="italic"
    >
      {navigator.userAgent.toUpperCase().indexOf('MAC') >= 0 ? (
        t('dashboard.cmd_click_for_quick_results')
      ) : (
        t('dashboard.ctrl_click_for_quick_results')
      )}
    </Box>
  );
};

export default DashboardBottomHelp;
