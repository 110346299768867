import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsTreemap from 'highcharts/modules/treemap';

HighchartsTreemap(Highcharts);
HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
      },
    },
    chart: {
      type: 'treemap',
    },
    title: { text: 'Titre Treemap' },
  },
};

const Treemap = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Treemap.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Treemap.defaultProps = {
};

export default Treemap;
