import React, { Fragment } from 'react';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const DocumentForWidgetList = ({
  qesdocument,
}) => {
  const { t } = useTranslation();

  // OBLIGÉ DE FAIRE UN OBJECT INTERMÉDIAIRE DÉGUEUX POUR TENTER D'AVOIR UN DOCUMENT
  // TYPE QES COMME DANS LES AUTRES ENDPOINTS
  const { title, linkout, date } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  return (
    <Box display="block">
      <Typography
        variant="h6"
        component="span"
        fontSize="0.8rem"
        lineHeight="1"
      >
        <Link
          underline="none"
          href={linkout}
          target="_blank"
          rel="noopener"
          color="text.dark"
        >
          <Box
            component="span"
            sx={{ cursor: 'pointer' }}
          >
            {title || t('document.title_undefined')}
          </Box>
        </Link>
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        flexGrow="1"
        lineHeight="1rem"
        sx={{ wordBreak: 'break-word' }}
      >
        {date && (
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              fontSize="0.8rem"
            >
              {date}
            </Typography>
            <Box mx={1} py="3px">
              <Divider
                orientation="vertical"
              />
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

DocumentForWidgetList.propTypes = {
  qesdocument: qesdocumentPropType,
};

DocumentForWidgetList.defaultProps = {
  qesdocument: {},
};

export default DocumentForWidgetList;
