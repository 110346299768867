import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import QES_CONSTANTS from 'generic/core/qes/constants';

import _ from 'lodash';

import {
  Grid,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';

import { refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import WidgetContainer from 'generic/containers/WidgetContainer';

import Bar from 'generic/components/dashboard-items/Bar';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import Map from 'generic/components/dashboard-items/Map';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import Sankey from 'generic/components/dashboard-items/Sankey';
import Datatable from 'generic/components/dashboard-items/Datatable';

import { getColorForFrequency } from 'generic/utils/colorUtils';
import goToMitre from 'generic/utils/mitreUtils';
import goToAlienVault from 'generic/utils/alienVault';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import Sunburst from 'generic/components/dashboard-items/Sunburst';
import THEME_CONFIG from 'generic/core/theme';

const { entitiesColors } = THEME_CONFIG.HIGHCHARTS;
const { DATE_INTERVAL_COMPARATOR } = QES_CONSTANTS;

const WORDCLOUD_CONCEPTS = {
  'QES_StixIdentity.verbatim': { field: 1032000587, label: 'identity' },
  'QES_StixThreatActor.verbatim': { field: 1032000568, label: 'threat_actors' },
  'QES_StixAttackPattern.verbatim': { field: 1032000572, label: 'attack_patterns' },
  'QES_StixInfrastructure.verbatim': { field: 1032000575, label: 'infrastructure' },
  'QES_StixMalware.verbatim': { field: 1032000576, label: 'malwares' },
  'QES_StixTool.verbatim': { field: 1032000579, label: 'tools' },
  'QES_StixObservedData.verbatim': { field: 1032000578, label: 'observed_data' },
};

const DATATABLE_CONCEPTS = {
  'QES_StixVulnerability.verbatim': { field: 1032000581, title: 'vulnerability', columnName: 'vulnerability' },
  'QES_Ipv4.verbatim': { field: 1032000582, title: 'top_50_ipv4', columnName: 'ipv4' },
  'QES_Ipv6.verbatim': { field: 1032000583, title: 'top_50_ipv6', columnName: 'ipv6' },
  'QES_UrlDomain.verbatim': { field: 1032000585, title: 'top_50_domain', columnName: 'domaine' },
  'QES_Md5.verbatim': {
    field: 1032000584, title: 'top_50_md5', columnName: 'md5', computeLink: goToAlienVault,
  },
  'QES_PathMain.verbatim': { field: 1032000605, title: 'top_50_path', columnName: 'path' },
  'QES_Sha1.verbatim': {
    field: 1032000606, title: 'top_50_sha1', columnName: 'sha1', computeLink: goToAlienVault,
  },
  'QES_MitreTechniques.verbatim': {
    field: 1032000588, title: 'top_50_mitre_technical', columnName: 'mitre_technical', computeLink: goToMitre,
  },
  'QES_MitreTactics.verbatim': {
    field: 1032000589, title: 'top_50_mitre_tactic', columnName: 'mitre_tactic', computeLink: goToMitre,
  },
  'QES_MitreSoftwares.verbatim': {
    field: 1032000590, title: 'top_50_mitre_software', columnName: 'mitre_software', computeLink: goToMitre,
  },
  'QES_MitreMitigations.verbatim': {
    field: 1032000592, title: 'top_50_mitre_mitigation', columnName: 'mitre_mitigation', computeLink: goToMitre,
  },
  'QES_MitreCampaigns.verbatim': {
    field: 1032000593, title: 'top_50_mitre_campaign', columnName: 'mitre_campaign', computeLink: goToMitre,
  },
};

let cooccurrenceFields = [
  { value: 'QES_StixIdentity.verbatim', name: 'Identity', idField: 1032000587 },
  { value: 'QES_StixThreatActor.verbatim', name: 'Threat Actor', idField: 1032000568 },
  { value: 'QES_StixAttackPattern.verbatim', name: 'Attack Pattern', idField: 1032000572 },
  { value: 'QES_StixInfrastructure.verbatim', name: 'Infrastructure', idField: 1032000575 },
  { value: 'QES_StixMalware.verbatim', name: 'Malware', idField: 1032000576 },
  { value: 'QES_StixTool.verbatim', name: 'Tool', idField: 1032000579 },
  { value: 'QES_StixObservedData.verbatim', name: 'Observed Data', idField: 1032000578 },
  { value: 'QES_Ipv4.verbatim', name: 'IPv4', idField: 1032000582 },
  { value: 'QES_Ipv6.verbatim', name: 'IPv6', idField: 1032000583 },
  { value: 'QES_Md5.verbatim', name: 'MD5', idField: 1032000584 },
].map((field, index) => ({
  ...field,
  color: entitiesColors[index],
}));

const generateDatatableFirstCell = (dispatch, fieldDefinition) => (value) => (
  <Box style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
    {fieldDefinition.computeLink && (
      <IconButton
        size="small"
        onClick={
          () => { window.open(fieldDefinition.computeLink(value), '_blank'); }
        }
        sx={{
          padding: 0.5,
          marginRight: 0.5,
          '& .MuiSvgIcon-root': {
            color: 'primary.attenuated',
          },
          '&:hover .MuiSvgIcon-root': {
            color: 'primary.main',
          },
        }}
      >
        <OpenInNewOutlined fontSize="small" />
      </IconButton>
    )}
    <Typography
      sx={{
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onClick={(event) => {
        dispatch(refineAddFacetsValues([
          {
            champ: fieldDefinition.field,
            strategie: value,
          },
        ], (event.ctrlKey || event.metaKey)));
      }}
      title={value}
    >
      {value}
    </Typography>
  </Box>
);

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // On utilise ici des refs pour pouvoir les mettre à jour
  // depuis les widgets "enfants" sans re-render tout le
  // dashboard
  const cooccurrencesNodesFilterValue = useRef([cooccurrenceFields[0].value]);
  const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');
  const widgets = {
    fileCreationDate: {
      facets: 'file_creation_date#auto_date_histogram|1000 _key:asc',
      aggregates: ['agg_file_creation_date'],
      seriesNames: [t('dashboard.widget.file_creation_date')],
      type: 'spline',
    },
    level0Level1: {
      facets: 'Level0, Level1',
      aggregates: ['agg_Level0'],
      pivots: ['agg_Level1'],
      type: 'sunburst',
    },
    locations: {
      facets: 'QES_Location.verbatim',
      aggregates: ['agg_QES_Location.verbatim'],
      seriesNames: [t('dashboard.widget.places_mentionned')],
      type: 'bar',
    },
    countryTopic: {
      facets: 'QES_CountryTopicCode.verbatim',
      aggregates: ['agg_QES_CountryTopicCode.verbatim'],
      type: 'map',
      mindoccount: 1,
      facetmax: 10000,
      facetmax2: 10000,
    },
    concepts: {
      facets: _.keys(WORDCLOUD_CONCEPTS).join(';'),
      aggregates: _.keys(WORDCLOUD_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(WORDCLOUD_CONCEPTS, 'label'),
      facetmax: 50,
      facetmax2: 3,
      type: 'wordcloud',
      splitPerSerie: true,
    },
    cooccurrences: {
      facets: cooccurrencesNodesFilterValueJoined,
      aggregates: [cooccurrencesNodesFilterValueJoined],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [cooccurrencesNodesFilterValueJoined],
      type: 'networkgraph',
    },
    datatablesConcepts: {
      facets: _.keys(DATATABLE_CONCEPTS).join(';'),
      aggregates: _.keys(DATATABLE_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(DATATABLE_CONCEPTS, 'title'),
      facetmax: 50,
      facetmax2: 3,
      splitPerSerie: true,
      type: 'datatable',
    },
    frequentRelations: {
      facets: 'QES_StixThreatActor.verbatim,QES_StixAttackPattern.verbatim,QES_StixTool.verbatim',
      aggregates: ['agg_QES_StixThreatActor.verbatim'],
      seriesNames: [t('dashboard.widget.most_frequent_relationships')],
      facetmax: 15,
      facetmax2: 3,
      mindoccount: 1,
      type: 'sankey',
    },
  };

  const handleRefreshCooccurrencesChart = (nodesTypesValues) => {
    cooccurrencesNodesFilterValue.current = nodesTypesValues;
    const joinedValue = nodesTypesValues.join(',');
    const keepWidgets = true;
    dispatch(fetchWidgets(strategy, {
      cooccurrences: {
        facets: joinedValue,
        aggregates: [joinedValue],
        facetmax: 200,
        facetmax2: 5,
        mindoccount: 1,
        seriesNames: [joinedValue],
        type: 'networkgraph',
      },
    }, keepWidgets));
  };

  const handleExportWidget = (widgetId, facet) => {
    const keepWidgets = true;
    const params = widgets[widgetId];
    if (facet) {
      params.facets = facet;
    }
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, {
      widgetId: params,
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(strategy, widgets));
    }

  // On ne met pas cooccurrencesNodesFilterValue ni relationsLinksFilterValue dans les dépendances,
  // pour éviter de refresh tout le dashboard quand on change juste le type
  // des éléments affichés (refresh gérés dans handleChangeCooccurrenceValue
  // et handleChangeRelationFieldValue).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, dispatch]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      <Grid item xs={12} sm={6} md={8}>
        <WidgetContainer widgetId="fileCreationDate">
          {({ series: fileCreationDate, height }) => (
            <DashboardWidget
              height={height}
              component={ColumnHisto}
              handleExportWidget={() => handleExportWidget('fileCreationDate')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: t('dashboard.widget.files_creation_date'), align: 'left' },
                  series: fileCreationDate,
                  legend: false,
                  yAxis: { title: { text: t('dashboard.widget.number_articles') } },
                  xAxis: {
                    title: { text: '' },
                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 0,
                    dateTimeLabelFormats: {
                      second: '%d/%m/%Y<br/>%H:%M:%S',
                      minute: '%d/%m/%Y<br/>%H:%M',
                      hour: '%d/%m/%Y<br/>%H:%M',
                      day: '%Y<br/>%d/%m',
                      month: '%m/%Y',
                      year: '%Y',
                    },
                    events: {
                      afterSetExtremes: (event) => {
                        if (event.trigger === 'zoom') {
                          const min = fileCreationDate[0].data[0][0];
                          const max = fileCreationDate[0].data[
                            fileCreationDate[0].data.length - 1
                          ][0];
                          let valmin = event.min;
                          let valmax = event.max;
                          if (valmin < min) {
                            valmin = min;
                          }
                          if (valmax > max) {
                            valmax = max;
                          }
                          dispatch(refineAddFacetsValues([
                            {
                              champ: 10000029,
                              formatFacet: 'date',
                              comparator: DATE_INTERVAL_COMPARATOR.between,
                              begin: format(new Date(valmin), 'yyyyMMdd'),
                              end: format(new Date(valmax), 'yyyyMMdd'),
                            },
                          ]));
                        }
                      },
                    },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="level0Level1">
          {({ series: level0Level1Serie, height }) => (
            <DashboardWidget
              height={height}
              component={Sunburst}
              handleExportWidget={() => handleExportWidget('level0Level1')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    sunburst: {
                      point: {
                        events: {
                          click: (event) => {
                            if (event.point.name && event.point.node.isLeaf) {
                              dispatch(refineAddFacetsValues([
                                { champ: 1032000608, strategie: event.point.name },
                              ], (event.ctrlKey || event.metaKey)));
                            } else if (event.shiftKey && event.point.node.index !== 0) {
                              dispatch(refineAddFacetsValues([
                                { champ: 1032000607, strategie: event.point.parent },
                              ], (event.ctrlKey || event.metaKey)));
                            }
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    data: _.get(level0Level1Serie, '[0].data', []),
                  }],
                  title: { text: t('dashboard.widget.level1_by_level0'), align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <WidgetContainer widgetId="countryTopic">
          {({ series: countryTopicSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Map}
              handleExportWidget={() => handleExportWidget('countryTopic')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      tooltip: {
                        pointFormatter: function getTranslatedCountryName() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                        },
                      },
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 42, strategie: event.point.options.name, dontQuoteStrategy: true },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  series: countryTopicSerie,
                  title: { text: t('dashboard.widget.countries_mentionned'), align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={6}>
        <WidgetContainer widgetId="locations">
          {({ series: locationsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Bar}
              handleExportWidget={() => handleExportWidget('locations')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 902, strategie: event.point.name },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  legend: false,
                  title: { text: t('dashboard.widget.places_mentionned_in_documents'), align: 'left' },
                  series: locationsSerie,
                  yAxis: {
                    title: {
                      text: t('dashboard.widget.values'),
                    },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {
        _.entries(
          _.pickBy(WORDCLOUD_CONCEPTS, (v, key) => ([
            'QES_StixIdentity.verbatim',
            'QES_StixThreatActor.verbatim',
            'QES_StixAttackPattern.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={4} key={`concepts${fieldName}`}>
            <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  height={height}
                  component={Wordcloud}
                  handleExportWidget={() => handleExportWidget('concepts', fieldName)}
                  highchartsOptions={{
                    options: {
                      chart: {
                        height,
                      },
                      plotOptions: {
                        wordcloud: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: fieldDefinition.field,
                                    strategie: event.point.options.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.map(conceptsSerie.data, ([word, weight]) => ({
                          name: word,
                          weight,
                          color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                        })),
                        name: t(`dashboard.widget.${conceptsSerie.name}`),
                      }],
                      title: { text: t(`dashboard.widget.${conceptsSerie.name}`), align: 'left' },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      {
        _.entries(
          _.pickBy(WORDCLOUD_CONCEPTS, (v, key) => ([
            'QES_StixInfrastructure.verbatim',
            'QES_StixMalware.verbatim',
            'QES_StixTool.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={3} key={`concepts${fieldName}`}>
            <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  height={height}
                  component={Wordcloud}
                  handleExportWidget={() => handleExportWidget('concepts', fieldName)}
                  highchartsOptions={{
                    options: {
                      chart: {
                        height,
                      },
                      plotOptions: {
                        wordcloud: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: fieldDefinition.field,
                                    strategie: event.point.options.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.map(conceptsSerie.data, ([word, weight]) => ({
                          name: word,
                          weight,
                          color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                        })),
                        name: t(`dashboard.widget.${conceptsSerie.name}`),
                      }],
                      title: { text: t(`dashboard.widget.${conceptsSerie.name}`), align: 'left' },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      {
        _.entries(
          _.pickBy(DATATABLE_CONCEPTS, (v, key) => ([
            'QES_StixVulnerability.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={3} key={`datatablesConcepts${fieldName}`}>
            <WidgetContainer widgetId={`datatablesConcepts_${fieldDefinition.title}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  component={Datatable}
                  handleExportWidget={() => handleExportWidget('datatablesConcepts', fieldName)}
                  componentProps={{
                    title: { text: t(`dashboard.widget.${fieldDefinition.title}`), align: 'left' },
                    columns: [
                      {
                        name: t(`dashboard.widget.${fieldDefinition.columnName}`),
                        cellRenderer: generateDatatableFirstCell(dispatch, fieldDefinition),
                      },
                      { name: t('dashboard.widget.count'), align: 'right' },
                    ],
                    data: conceptsSerie.data,
                  }}
                  height={height}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      {
        _.entries(
          _.pickBy(WORDCLOUD_CONCEPTS, (__, key) => ([
            'QES_StixObservedData.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={3} key={`concepts${fieldName}`}>
            <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  height={height}
                  component={Wordcloud}
                  handleExportWidget={() => handleExportWidget('concepts', fieldName)}
                  highchartsOptions={{
                    options: {
                      chart: {
                        height,
                      },
                      plotOptions: {
                        wordcloud: {
                          point: {
                            events: {
                              click: (event) => {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: fieldDefinition.field,
                                    strategie: event.point.options.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              },
                            },
                          },
                        },
                      },
                      series: [{
                        data: _.map(conceptsSerie.data, ([word, weight]) => ({
                          name: word,
                          weight,
                          color: getColorForFrequency(0.35, conceptsSerie.minFreq, conceptsSerie.maxFreq, weight, 0),
                        })),
                        name: t(`dashboard.widget.${conceptsSerie.name}`),
                      }],
                      title: { text: t(`dashboard.widget.${conceptsSerie.name}`), align: 'left' },
                    },
                  }}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }
      {
        _.entries(
          _.pickBy(DATATABLE_CONCEPTS, (v, key) => ([
            'QES_Ipv4.verbatim',
            'QES_Ipv6.verbatim',
            'QES_UrlDomain.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={3} key={`datatablesConcepts${fieldName}`}>
            <WidgetContainer widgetId={`datatablesConcepts_${fieldDefinition.title}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  component={Datatable}
                  handleExportWidget={() => handleExportWidget('datatablesConcepts', fieldName)}
                  componentProps={{
                    title: { text: t(`dashboard.widget.${fieldDefinition.title}`), align: 'left' },
                    columns: [
                      {
                        name: t(`dashboard.widget.${fieldDefinition.columnName}`),
                        cellRenderer: generateDatatableFirstCell(dispatch, fieldDefinition),
                      },
                      { name: t('dashboard.widget.count'), align: 'right' },
                    ],
                    data: conceptsSerie.data,
                  }}
                  height={height}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      <Grid item xs={12} sm={12} md={12}>
        <WidgetContainer widgetId="frequentRelations">
          {({ series: relations, height }) => (
            <DashboardWidget
              height={height}
              component={Sankey}
              handleExportWidget={() => handleExportWidget('frequentRelations')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  xAxis: {
                    scrollbar: {
                      enabled: true,
                    },
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (event) => {
                            const nodesIdsMap = {
                              0: 1032000568,
                              1: 1032000572,
                              2: 1032000579,
                            };
                            let fields;
                            if (event.point.from) {
                              // On a cliqué sur un lien, donc on prépare le double raffinage
                              fields = [
                                {
                                  champ: nodesIdsMap[event.point.fromNode.column],
                                  strategie: event.point.fromNode.name,
                                },
                                {
                                  champ: nodesIdsMap[event.point.toNode.column],
                                  strategie: event.point.toNode.name,
                                },
                              ];
                            } else {
                              // On a cliqué sur un noeud, on fait un simple raffinage
                              fields = [
                                { champ: nodesIdsMap[event.point.column], strategie: event.point.name },
                              ];
                            }
                            dispatch(refineAddFacetsValues(fields, (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  series: relations,
                  title: { text: t('dashboard.widget.frequent_relationships'), align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {
        _.entries(
          _.pickBy(DATATABLE_CONCEPTS, (v, key) => ([
            'QES_Md5.verbatim',
            'QES_PathMain.verbatim',
            'QES_Sha1.verbatim',
            'QES_MitreTechniques.verbatim',
            'QES_MitreTactics.verbatim',
            'QES_MitreSoftwares.verbatim',
            'QES_MitreMitigations.verbatim',
            'QES_MitreCampaigns.verbatim',
          ].includes(key))),
        ).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={3} key={`datatablesConcepts${fieldName}`}>
            <WidgetContainer widgetId={`datatablesConcepts_${fieldDefinition.title}`}>
              {({ series: conceptsSerie, height }) => (
                <DashboardWidget
                  component={Datatable}
                  handleExportWidget={() => handleExportWidget('datatablesConcepts', fieldName)}
                  componentProps={{
                    title: { text: t(`dashboard.widget.${fieldDefinition.title}`), align: 'left' },
                    columns: [
                      {
                        name: t(`dashboard.widget.${fieldDefinition.columnName}`),
                        cellRenderer: generateDatatableFirstCell(dispatch, fieldDefinition),
                      },
                      { name: t('dashboard.widget.count'), align: 'right' },
                    ],
                    data: conceptsSerie.data,
                  }}
                  height={height}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="cooccurrences" height={700}>
          {({ series: cooccurrenceItem, height, additionalData }) => {
            cooccurrenceFields = cooccurrenceFields.map(
              (cooccurrenceField) => ({
                ...cooccurrenceField,
                nbNodes: _.get(additionalData.nodeCounts, cooccurrenceField.value, 0),
              }),
            );
            return (
              <DashboardWidget
                height={height}
                component={NetworkGraph}
                componentProps={{
                  nodesFilterValue: cooccurrencesNodesFilterValue.current,
                  nodesFilterItems: cooccurrenceFields,
                  handleRefreshChart: handleRefreshCooccurrencesChart,
                  handleLinkClick: (event, fromNode, toNode) => {
                    const {
                      idField: fromIdField,
                    } = _.find(cooccurrenceFields, { value: fromNode.options.group });
                    const {
                      idField: toIdField,
                    } = _.find(cooccurrenceFields, { value: toNode.options.group });
                    const facetsValues = [{
                      champ: fromIdField,
                      strategie: fromNode.id,
                    }];
                    if (fromIdField === toIdField) {
                      // Si jamais l'ID de champ est le même pour les deux noeuds,
                      // on colle les deux valeurs dans un même tableau, qui sera
                      // découpé convenablement au moment du fetch des résultats
                      facetsValues[0].strategie = [fromNode.id, toNode.id];
                    } else {
                      // A l'inverse, si les ID de champs sont différents, on
                      // on ajoute une seconde "facetValue" avec les éléments
                      // du second noeud
                      facetsValues.push({
                        champ: toIdField,
                        strategie: toNode.id,
                      });
                    }
                    dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                  },
                }}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      series: {
                        point: {
                          events: {
                            click: (event) => {
                              const { idField } = _.find(cooccurrenceFields, { value: event.point.options.group });
                              if (idField) {
                                dispatch(refineAddFacetsValues([
                                  {
                                    champ: idField,
                                    strategie: event.point.name,
                                  },
                                ], (event.ctrlKey || event.metaKey)));
                              }
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      nodes: _.get(cooccurrenceItem, '[0].nodes', []).map(
                        (node) => ({
                          ...node,
                          color: _.find(cooccurrenceFields, { value: node.group })?.color,
                        }),
                      ),
                      data: _.get(cooccurrenceItem, '[0].data', []),
                    }],
                    title: {
                      text: t('dashboard.widget.cooccurrences_graph'),
                      align: 'left',
                    },
                  },
                }}
              />
            );
          }}
        </WidgetContainer>
      </Grid>
    </Grid>
  );
};

export default DashboardChartsContainer;
