const primary = '#38939E';

const themeConfig = {
  THEME: {
    palette: {
      primary: {
        main: primary,
        transparent: `${primary}20`,
        dark: '#227781',
        attenuated: `${primary}80`,
      },
      secondary: {
        main: '#212529',
      },
      text: {
        neutral: '#212529',
      },
    },
  },
};

export default themeConfig;
