import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  Place,
  AccountCircle,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import QES_CONSTANTS from 'generic/core/qes/constants';
import THEME_CONFIG from 'generic/core/theme';
import goToCVE from 'generic/utils/cveUtils';
import goToMitre from 'generic/utils/mitreUtils';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;
const { relationsEntitiesColors } = THEME_CONFIG.HIGHCHARTS;

const Document = ({
  disableTagsRefine,
  displayTitle,
  document,
  handleGetDocumentComplete,
  isCompleteDisplay,
}) => {
  const { t } = useTranslation();
  const {
    date, pdf, snippet, sentences, status, text, title, linkout, attachment,
  } = useDocumentFields(document);
  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: pdf || linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            sx={{ cursor: 'pointer' }}
            color="text.dark"
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {date && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {date}
                </Typography>
              )}
              {status > 0 && (
                <Fragment>
                  { date && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                </Fragment>
              )}

              {(pdf || linkout) && (
                <Fragment>
                  {(status > 0 || date) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={pdf || linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      PDF
                    </Typography>
                  </Link>
                </Fragment>
              )}

              {attachment && (
                <Fragment>
                  {(pdf || linkout) && (<Box mx={1} py="3px"><Divider orientation="vertical" /></Box>)}
                  <Link
                    href={attachment}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      JSON
                    </Typography>
                  </Link>
                </Fragment>
              )}
            </Box>

            {sentences && (
              sentences.map((sentence) => (
                <Box p={1} mt={0.5} mb={1} borderLeft="5px solid #d3d3d3" whiteSpace="pre-wrap" lineHeight="22px">
                  {sentence}
                </Box>
              ))
            )}
            {!isCompleteDisplay && (
              <div>
                {snippet}
              </div>
            )}
            <ExpandableTagBox
              disableRefine={disableTagsRefine}
              qesdocument={qesdocument}
              tags={[
                { fieldName: 'QES_Company', icon: AccountBalance, fieldId: 1032000431 },
                { fieldName: 'QES_Person', icon: AccountCircle, fieldId: 901 },
                { fieldName: 'QES_Product', icon: Place, fieldId: 24 },
                { fieldName: 'QES_Concept', fieldId: 30800115 },
                { fieldName: 'QES_StixVulnerability', fieldId: 1032000586, externalLink: goToCVE },
                { fieldName: 'QES_MitreTactics', fieldId: 1032000589, externalLink: goToMitre },
                { fieldName: 'QES_MitreTechniques', fieldId: 1032000588, externalLink: goToMitre },
                { fieldName: 'QES_MitreMitigations', fieldId: 1032000592, externalLink: goToMitre },
                { fieldName: 'QES_MitreSoftwares', fieldId: 1032000590, externalLink: goToMitre },
                { fieldName: 'QES_MitreCampaigns', fieldId: 1032000593, externalLink: goToMitre },
                { fieldName: 'QES_Ipv4', fieldId: 1032000582 },
                { fieldName: 'QES_Ipv6', fieldId: 1032000583 },
              ].map((field, index) => ({
                ...field,
                color: relationsEntitiesColors[index],
              }))}
            />
            {isCompleteDisplay && (
              <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                {text}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  // description du document QES attendu pour ce client
  document: PropTypes.shape({
    etat: PropTypes.number,
    base: PropTypes.number,
    qesdocument: PropTypes.shape({
      file_creation_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
    files_pdf: PropTypes.string,
    files_text: PropTypes.string,
    title: PropTypes.string,
  }),
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
};

Document.defaultProps = {
  disableTagsRefine: false,
  displayTitle: true,
  document: {},
  handleGetDocumentComplete: null,
  isCompleteDisplay: false,
};

export default Document;
