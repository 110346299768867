import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Stack,
  Skeleton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { sendNewsletter, types } from 'generic/core/newsletters/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';

const NewsletterPreviewAndSendContainer = ({ id, variant }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sendingNewsletter = useSelector((state) => state.newsletters.sending);

  const [visualizationIframeLoading, setVisualizationIframeLoading] = useState(true);
  const [refTs, setRefreshTimeStamp] = useState(new Date());

  const handleIframeLoading = useCallback(
    (loading) => () => {
      setVisualizationIframeLoading(loading);
      // le timestamp permet de "forcer" le refresh d'une iframe "facilement"
      if (loading) setRefreshTimeStamp(new Date());
    },
    [setVisualizationIframeLoading, setRefreshTimeStamp],
  );

  const { API_ENTRY_POINT } = QES_CONSTANTS;
  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  const endpoint = variant;
  const disableButtons = visualizationIframeLoading || sendingNewsletter;

  let handleSend;
  let handleSendTest;
  let handleSendHisto;

  if (variant === 'newsletter-histo') {
    handleSendHisto = () => {
      dispatch(setConfirmDialogData({
        title: t('newsletters.resend'),
        message: `${t('newsletters.resend_confirmation')}`,
        submitColor: 'error',
        waitForActions: [types.SEND_NEWSLETTER_HISTO_SUCCESS, types.SEND_NEWSLETTER_HISTO_ERROR],
        action: {
          type: types.SEND_NEWSLETTER_HISTO,
          id,
          params: {
            // todo: une liste modifié des destinataires ?
          },
        },
      }));
    };
  } else {
    handleSend = () => {
      dispatch(setConfirmDialogData({
        title: t('newsletters.send_and_wipe_confirmation_title'),
        message: `${t('newsletters.send_and_wipe_confirmation')}`,
        submitColor: 'error',
        waitForActions: [types.SEND_NEWSLETTER_SUCCESS, types.SEND_NEWSLETTER_ERROR],
        action: {
          type: types.SEND_NEWSLETTER,
          id,
          params: {
            uriParams: { wipeDocuments: true },
          },
        },
      }));
    };

    handleSendTest = () => dispatch(sendNewsletter(id));
  }
  const previewSrc = `${API_ENTRY_POINT}/${endpoint}/print/${id}?logon=${logon}&key=${key}&ts=${refTs}`;

  return (
    <Box
      display="flex"
      flexGrow="1"
      className="desktopOnlyOverflow"
      flexDirection="column"
      padding={0}
    >
      {visualizationIframeLoading && (
        <Skeleton
          variant="rectangular"
          sx={{
            width: '600px',
            height: '100%',
            margin: 'auto',
          }}
        />
      )}

      <iframe
        style={{
          width: '100%',
          height: '100%',
          border: 0,
          display: visualizationIframeLoading ? 'none' : 'block',
        }}
        title="newsletter preview"
        src={previewSrc}
        onLoad={handleIframeLoading(false)}
      />

      <Box
        className="bottomBtnsContainer"
      >
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          { handleSendTest && (
            <LoadingButton
              color="primary"
              variant="text"
              onClick={handleSendTest}
              disabled={disableButtons}
              loading={sendingNewsletter}
            >
              {t('newsletters.send_test')}
            </LoadingButton>
          )}

          {handleSend && (
            <LoadingButton
              onClick={handleSend}
              disabled={disableButtons}
              loading={sendingNewsletter}
            >
              {t('newsletters.send_and_wipe_documents')}
            </LoadingButton>
          )}

          {handleSendHisto && (
            <LoadingButton
              onClick={handleSendHisto}
              disabled={disableButtons}
              loading={sendingNewsletter}
            >
              {t('newsletters.resend')}
            </LoadingButton>
          )}

        </Stack>
      </Box>
    </Box>
  );
};

NewsletterPreviewAndSendContainer.propTypes = {
  id: PropTypes.number.isRequired,
  variant: PropTypes.oneOf([
    'newsletter',
    'newsletter-histo',
  ]).isRequired,
};

export default NewsletterPreviewAndSendContainer;
