const en = {
  en: {
    translation: {
      dashboard: {
        widget: {
          domaine: 'Domaine',
          file_creation_date: 'File creation date',
          files_creation_date: 'Files creation date',
          frequent_relationships: 'Frequent relationships',
          identity: 'Identity',
          infrastructure: 'Infrastructure',
          ipv4: 'Ipv4',
          ipv6: 'Ipv6',
          level1_by_level0: 'Level1 by Level0',
          md5: 'MD5',
          mitre_campaign: 'Mitre Campaign',
          mitre_mitigation: 'Mitre Mitigation',
          mitre_tactic: 'Mitre Tactic',
          mitre_technical: 'Mitre Technical',
          mitre_software: 'Mitre Software',
          most_frequent_relationships: 'Most frequent relationships',
          path: 'Path',
          sha1: 'SHA1',
          top_50_ipv4: 'Top 50 Ipv4',
          top_50_ipv6: 'Top 50 Ipv6',
          top_50_md5: 'Top 50 MD5',
          top_50_mitre_campaign: 'Top 50 Mitre Campaign',
          top_50_mitre_mitigation: 'Top 50 Mitre Mitigation',
          top_50_mitre_tactic: 'Top 50 Mitre Tactic',
          top_50_mitre_technical: 'Top 50 Mitre Technical',
          top_50_mitre_software: 'Top 50 Mitre Software',
          top_50_path: 'Top 50 Path',
          top_50_sha1: 'Top 50 SHA1',
          vulnerability: 'Vulnerability',
        },
      },
    },
  },
};

export default en;
