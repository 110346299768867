import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import THEME_CONFIG from 'generic/core/theme';

const selectWidgetById = (state, widgetId) => _.get(state, `dashboard.widgets[${widgetId}]`, null);

const WidgetContainer = ({
  widgetId,
  children,
  height,
  fullHeight,
  flat,
}) => {
  const widgetData = useSelector((state) => selectWidgetById(state, widgetId));
  const theme = useTheme();

  if (widgetData === null) {
    // La props height de cette Box est calculée à partir de "height" passé dans les
    // props (ou celle des defaultProps) + 5 fois le spacing du thème, car on veut
    // que la hauteur soit la même que celle du graphique dans DashboardWidget, qui
    // a la même height + 5 "unités" de padding :
    // <Box ml={0} height={height} p={2} pb={3}>
    let skeletonHeight = height + parseInt(theme.spacing(5), 10);
    if (fullHeight) {
      skeletonHeight = '100%';
    } else if (flat) {
      skeletonHeight = height;
    }
    return (
      <Box
        height={skeletonHeight}
        flexShrink="0"
      >
        <Skeleton sx={{ width: '100%', height: '100%', transform: 'inherit' }} />
      </Box>
    );
  }
  const {
    series,
    axisX,
    axisY,
    additionalData,
  } = widgetData;
  return children({
    series,
    axisX,
    axisY,
    height,
    additionalData,
  });
};

WidgetContainer.propTypes = {
  widgetId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  height: PropTypes.number,
  fullHeight: PropTypes.bool,
  flat: PropTypes.bool,
};

WidgetContainer.defaultProps = {
  height: THEME_CONFIG.HIGHCHARTS.chartsDefaultHeight,
  fullHeight: false,
  flat: false,
};

export default WidgetContainer;
