import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);
HighchartsSankey(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      series: {
        animation: false,
      },
    },
    chart: { type: 'sankey' },
    credits: { enabled: false },
    title: { text: 'Titre Sankey' },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
      },
    },
  },
};

const Sankey = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Sankey.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Sankey.defaultProps = {
};

export default Sankey;
