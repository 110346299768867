const fr = {
  fr: {
    translation: {
      dashboard: {
        widget: {
          domaine: 'Domaines',
          file_creation_date: 'Date de création du fichier',
          files_creation_date: 'Date de création des fichiers',
          frequent_relationships: 'Relations fréquentes',
          identity: 'Identité',
          infrastructure: 'Infrastructure',
          ipv4: 'Ipv4',
          ipv6: 'Ipv6',
          level1_by_level0: 'Niveau1 par niveau0',
          md5: 'MD5',
          mitre_campaign: 'Mitre de Campagne',
          mitre_mitigation: 'Mitre d\'Atténuation',
          mitre_tactic: 'Mitre Tactique',
          mitre_technical: 'Mitre Technique',
          mitre_software: 'Mitre Logiciel',
          most_frequent_relationships: 'Relations les plus fréquentes',
          path: 'Chemins',
          sha1: 'SHA1',
          top_50_ipv4: 'Top 50 Ipv4',
          top_50_ipv6: 'Top 50 Ipv6',
          top_50_md5: 'Top 50 MD5',
          top_50_mitre_campaign: 'Top 50 Mitre de Campagne',
          top_50_mitre_mitigation: 'Top 50 Mitre d\'Atténuation',
          top_50_mitre_tactic: 'Top 50 Mitre Tactique',
          top_50_mitre_technical: 'Top 50 Mitre Technique',
          top_50_mitre_software: 'Top 50 Mitre Logiciel',
          top_50_path: 'Top 50 des chemins',
          top_50_sha1: 'Top 50 SHA1',
          vulnerability: 'Vulnérabilité',
        },
      },
    },
  },
};

export default fr;
