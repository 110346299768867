import React from 'react';
import PropTypes from 'prop-types';

import Document from 'custom/smartcti/components/documents/Document';
import DocumentRelation from 'custom/smartcti/components/documents/DocumentRelation';

const DocumentWrapper = ({
  document,
  ...rest
}) => {
  if (document.base === 22801208) {
    return <DocumentRelation document={document} {...rest} />;
  }
  return <Document document={document} {...rest} />;
};

DocumentWrapper.propTypes = {
  document: PropTypes.shape().isRequired,
};

export default DocumentWrapper;
