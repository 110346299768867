import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  TextField,
} from 'formik-mui';
import {
  Box,
} from '@mui/material';
import {
  Search,
} from '@mui/icons-material';
import { alpha, getContrastRatio } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const headerBackgroundColor = theme.palette?.header?.main || theme.palette.primary.main;
  const headerBackgroundColorIsDark = getContrastRatio(headerBackgroundColor, '#fff') > 3;
  let itemsColors = '#000000';
  if (headerBackgroundColorIsDark) {
    itemsColors = '#ffffff';
  }
  return {
    search: {
      borderRadius: '18px',
      position: 'relative',
      backgroundColor: alpha(itemsColors, 0.15),
      '&:hover': {
        backgroundColor: alpha(itemsColors, 0.25),
      },
      marginRight: '16px',
      marginLeft: 0,
      width: '100%',
      color: itemsColors,
      [theme.breakpoints.up('sm')]: {
        marginLeft: '24px',
        width: 'auto',
      },
    },
    searchIcon: {
      padding: '0 8px',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      '&::before, &:hover': {
        border: 'none',
      },
      color: '#fff',
    },
    inputInput: {
      padding: '9px 8px 8px 40px',
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('md')]: {
        width: '60ch',
      },
    },
  };
});

const FormSearchSimple = ({
  onSubmit,
  initialValues,
  fieldCode,
  fieldPlaceholder,
  fieldIcon,
}) => {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            {fieldIcon || <Search />}
          </Box>

          <Form>
            <Field
              sx={{ m: 0 }}
              variant="standard"
              component={TextField}
              fullWidth
              label=""
              name={fieldCode}
              autoFocus
              placeholder={fieldPlaceholder}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                disableUnderline: true,
              }}
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
};

FormSearchSimple.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldCode: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string.isRequired,
  fieldIcon: PropTypes.node,
};

FormSearchSimple.defaultProps = {
  initialValues: {},
  fieldIcon: null,
};

export default FormSearchSimple;
