import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Checkbox,
  Typography,
  Divider,
  Chip,
  Link,
  Button,
  Tooltip,
  List,
  ListItem,
  Skeleton,
} from '@mui/material';
import { Folder, MailOutline } from '@mui/icons-material';
import { format, formatDistance } from 'generic/utils/dateUtils';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import NetworkProfile from 'generic/components/ui/NetworkProfile';
import { articlePropType } from 'generic/core/anr/proptypes';
import DuplicatesCount from 'generic/components/ui/DuplicatesCount';
import ResultItemActionsMenu from 'generic/components/ui/ResultItemActionsMenu';
import ArticleActionsContainer from 'generic/containers/anr/ArticleActionsContainer';

const useStyles = makeStyles({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

const SendingsEmails = ({ sendings }) => {
  // Pour afficher la liste d'Emails ligne par ligne dans le Tooltip,
  // on prend chaque array 'emails' de chaque sending, on les concat
  // ensemble, et on dédoublonne le tout, avant de les afficher
  const emails = _.uniq(_.concat(_.flatMap(sendings, 'emails')));
  return (
    _.map(
      emails,
      (email) => <div key={email}>{email}</div>,
    )
  );
};

const ResultItem = ({
  item,
  view,
  smallerThanMedium,
  handleOpenArticle,
  handleSourceFilter,
  handleFilterFolder,
  checkboxChecked,
  handleToggleCheckbox,
  duplicatesIsVisible,
  handleVisibleDuplicates,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box
        display="block"
        backgroundColor={item.opened ? 'rgba(0, 0, 0, 0.06)' : 'inherit'}
        px={2}
        pt={1}
        pb={0.5}
      >
        {/* Checkbox + heure + titre */}
        <Box display="flex" alignItems="flex-start">
          {!item.is_duplicate && (
            <Checkbox
              sx={{ margin: 0, padding: '4px' }}
              checked={checkboxChecked}
              onClick={handleToggleCheckbox}
            />
          )}
          <Typography
            variant="h6"
            component="span"
            fontSize="1.2rem"
            color={!_.isEmpty(item.validation) ? 'success.light' : 'text.dark'}
            margin="5px 0 8px"
            display="block"
          >
            {!_.isEmpty(item.sendings) && (
              <Box
                component="span"
                mr={0.5}
                color="text.dark"
              >
                <Tooltip
                  title={<SendingsEmails sendings={item.sendings} />}
                  sx={{ verticalAlign: 'bottom' }}
                >
                  <MailOutline />
                </Tooltip>
              </Box>
            )}
            <Box
              component="span"
              sx={{ cursor: 'pointer' }}
              onClick={(event) => handleOpenArticle(event, item)}
            >
              {item.title}
            </Box>
            <DuplicatesCount item={item} />
          </Typography>
        </Box>
        {/* Image + menu d'actions + description + source + dossiers/mots-clés */}
        <Box display="flex" alignItems="flex-start">
          <ResultItemActionsMenu>
            <ArticleActionsContainer
              article={item}
            />
          </ResultItemActionsMenu>
          <Box flexGrow="1">
            {/* Image et menu d'actions */}
            {!smallerThanMedium && !_.isEmpty(item.images) && !item.is_from_dark_web && (
              <Box mr={1} sx={{ float: 'left' }}>
                <ImgLoader
                  src={item.images[0]}
                  fallbackComponent={(
                    <ImageNotFound
                      height={60}
                      width={100}
                    />
                  )}
                  className={classes.itemImage}
                  skeletonClassName={classes.itemImage}
                  alt={t('results.article_image')}
                  height={60}
                  width={100}
                  loading="lazy"
                />
              </Box>
            )}
            {/* Description + source + dossiers/mots-clés */}
            <Box lineHeight="1.3rem">
              {/* Source (profil réseau social / site / date distance) */}
              <Box
                display="flex"
                mb={0.5}
                sx={{ wordBreak: 'break-word' }}
                flexWrap="wrap"
              >
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {format(new Date(item.display_date * 1000), 'HH:mm')}
                  &nbsp;-&nbsp;
                  {formatDistance(new Date(item.display_date * 1000), new Date(), { addSuffix: true })}
                </Typography>
                {item.quote_from && (
                  <Fragment>
                    <Box mx={1} py="3px">
                      <Divider
                        orientation="vertical"
                      />
                    </Box>
                    <Link
                      underline="none"
                      href={item.atfromurl}
                      target="_blank"
                      rel="noopener"
                      display="flex"
                      alignItems="center"
                    >
                      <NetworkProfile
                        quoteType={item.quote_type}
                        quoteFrom={item.quote_from}
                        followersCount={item.followers_count}
                      />
                    </Link>
                  </Fragment>
                )}
                {item.source && (
                  <Fragment>
                    <Box mx={1} py="3px">
                      <Divider
                        orientation="vertical"
                      />
                    </Box>
                    {item.quote_from ? (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.neutral"
                      >
                        {item.source}
                      </Typography>
                    ) : (
                      <Tooltip title={t('anr.actions.filterOnSite')} placement="right">
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.neutral"
                          onClick={handleSourceFilter}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          {item.source}
                        </Typography>
                      </Tooltip>
                    )}
                  </Fragment>
                )}
              </Box>
              <Box>
                {item.snippet ? (
                  // eslint-disable-next-line react/no-danger
                  <div dangerouslySetInnerHTML={{ __html: item.snippet }} />
                ) : (
                  item.short_description
                )}
              </Box>
              {_.includes(['glimpse', 'last_archives'], view) && (
                <Box lineHeight="1.5rem">
                  {_.map(
                    item.folders,
                    (folder) => (
                      <Tooltip key={folder.id} title={t('anr.actions.filterOnFolder')} placement="bottom">
                        <Chip
                          size="extraSmall"
                          variant="filledSecondary"
                          label={folder.title}
                          icon={<Folder />}
                          sx={{ mr: 0.5 }}
                          onClick={() => handleFilterFolder(folder.title)}
                        />
                      </Tooltip>
                    ),
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Bouton pour voir les duplicates et container des articles duplicates */}
      {!item.is_duplicate && item.group_count > 1 && (
        <Box mt={0.5} ml={5.5}>
          <Button
            variant="text"
            size="extraSmall"
            color="secondary"
            disableElevation
            sx={{ textTransform: 'none' }}
            onClick={() => handleVisibleDuplicates(item)}
          >
            {duplicatesIsVisible ? t('results.duplicates.hide_duplicates') : t('results.duplicates.show_duplicates') }
          </Button>
          {duplicatesIsVisible && (
            item.loadingDuplicates ? (
              <Box marginTop={1}>
                <Skeleton variant="rounded" width="100%" height={250} />
              </Box>
            ) : (
              <List dense>
                <Box borderLeft="1px solid">
                  {_.map(item.duplicates, (duplicateArticle) => (
                    <ListItem
                      key={duplicateArticle.id}
                      sx={{
                        display: 'block',
                        wordBreak: 'break-word',
                        p: 0,
                      }}
                    >
                      <ResultItem
                        item={duplicateArticle}
                        view={view}
                        smallerThanMedium={smallerThanMedium}
                        handleOpenArticle={(event) => handleOpenArticle(event, duplicateArticle)}
                        handleSourceFilter={() => handleSourceFilter(duplicateArticle.source)}
                        handleFilterFolder={handleFilterFolder}
                      />
                    </ListItem>
                  ))}
                </Box>
              </List>
            )
          )}
        </Box>
      )}
    </Fragment>
  );
};

ResultItem.propTypes = {
  item: articlePropType.isRequired,
  view: PropTypes.string,
  smallerThanMedium: PropTypes.bool,
  handleOpenArticle: PropTypes.func.isRequired,
  handleSourceFilter: PropTypes.func.isRequired,
  handleFilterFolder: PropTypes.func.isRequired,
  checkboxChecked: PropTypes.bool,
  handleToggleCheckbox: PropTypes.func,
  duplicatesIsVisible: PropTypes.bool,
  handleVisibleDuplicates: PropTypes.func,
};

ResultItem.defaultProps = {
  view: '',
  smallerThanMedium: false,
  checkboxChecked: false,
  handleToggleCheckbox: null,
  duplicatesIsVisible: false,
  handleVisibleDuplicates: null,
};
export default React.memo(ResultItem);
