import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { fetchResults, refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets, setDashboardLoading } from 'generic/core/dashboard/actions';

import WidgetContainer from 'generic/containers/WidgetContainer';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import Pie from 'generic/components/dashboard-items/Pie';
import Treemap from 'generic/components/dashboard-items/Treemap';
import Datatable from 'generic/components/dashboard-items/Datatable';
import { getRandomColor } from 'generic/utils/colorUtils';
import THEME_CONFIG from 'generic/core/theme';
import { snackActions } from 'generic/utils/snackbar';

const { relationsEntitiesColors } = THEME_CONFIG.HIGHCHARTS;

const DATATABLE_CONCEPTS = {
  'QES_Relation_Source_Text.verbatim': {
    field: 1032000601, title: 'Top 50 left part', columnName: 'Text',
  },
  'QES_Relation_Keyword.verbatim': {
    field: 1032000602, title: 'Top 50 central part', columnName: 'Text',
  },
  'QES_Relation_Destination_Text.verbatim': {
    field: 1032000603, title: 'Top 50 right part', columnName: 'Text',
  },
};

let relationsNodesFields = [
  { value: 'Organonoff', name: 'Organonoff', idField: 63 },
  { value: 'Organization', name: 'Organization', idField: 920 },
  { value: 'Product', name: 'Product', idField: 24 },
  { value: 'Person', name: 'Person', idField: 901 },
  { value: 'Concept', name: 'Concept', idField: 30800115 },
  { value: 'Coldconcept', name: 'Coldconcept', idField: 922 },
  { value: 'Location', name: 'Location', idField: 902 },
  { value: 'Company', name: 'Company', idField: 1032000431 },
  { value: 'Event', name: 'Event', idField: 1032000315 },
  { value: 'Uppers', name: 'Uppers', idField: 1032000609 },
  { value: 'Media', name: 'Media', idField: 1032000610 },
].map((field, index) => ({
  ...field,
  color: relationsEntitiesColors[index],
}));

let relationsLinksFields = [
  { name: 'Find', value: 'Find' },
  { name: 'Use', value: 'Use' },
  { name: 'Target', value: 'Target' },
  { name: 'Report', value: 'Report' },
  { name: 'Attack', value: 'Attack' },
  { name: 'Aim', value: 'Aim' },
  { name: 'Launch', value: 'Launch' },
  { name: 'Identify', value: 'Identify' },
  { name: 'Investigate', value: 'Investigate' },
  { name: 'Supply', value: 'Supply' },
  { name: 'Detect', value: 'Detect' },
  { name: 'Deploy', value: 'Deploy' },
  { name: 'Run', value: 'Run' },
  { name: 'Create', value: 'Create' },
  { name: 'Support', value: 'Support' },
  { name: 'Develop', value: 'Develop' },
  { name: 'Exploit', value: 'Exploit' },
  { name: 'Help', value: 'Help' },
  { name: 'Include', value: 'Include' },
  { name: 'Appoint', value: 'Appoint' },
  { name: 'Hit', value: 'Hit' },
  { name: 'Expose', value: 'Expose' },
  { name: 'Kill', value: 'Kill' },
  { name: 'Steal', value: 'Steal' },
  { name: 'Observe', value: 'Observe' },
  { name: 'Track', value: 'Track' },
  { name: 'Attribute', value: 'Attribute' },
  { name: 'Tell', value: 'Tell' },
  { name: 'Buy', value: 'Buy' },
  { name: 'Provide', value: 'Provide' },
  { name: 'Lead', value: 'Lead' },
  { name: 'Compromise', value: 'Compromise' },
  { name: 'Conduct', value: 'Conduct' },
  { name: 'Utilize', value: 'Utilize' },
  { name: 'Suffer', value: 'Suffer' },
  { name: 'Take', value: 'Take' },
  { name: 'Conflict', value: 'Conflict' },
  { name: 'Test', value: 'Test' },
  { name: 'Dismiss', value: 'Dismiss' },
  { name: 'Uncover', value: 'Uncover' },
  { name: 'Impact', value: 'Impact' },
  { name: 'Involve', value: 'Involve' },
  { name: 'Affect', value: 'Affect' },
  { name: 'Deliver', value: 'Deliver' },
  { name: 'Issue', value: 'Issue' },
  { name: 'Prevent', value: 'Prevent' },
  { name: 'Introduce', value: 'Introduce' },
  { name: 'Manage', value: 'Manage' },
  { name: 'Declare', value: 'Declare' },
  { name: 'Alert', value: 'Alert' },
  { name: 'Evolve', value: 'Evolve' },
  { name: 'Host', value: 'Host' },
  { name: 'Leverage', value: 'Leverage' },
  { name: 'Say', value: 'Say' },
  { name: 'See', value: 'See' },
  { name: 'Confirm', value: 'Confirm' },
  { name: 'Contain', value: 'Contain' },
  { name: 'Infect', value: 'Infect' },
  { name: 'Receive', value: 'Receive' },
  { name: 'Describe', value: 'Describe' },
  { name: 'Encourage', value: 'Encourage' },
  { name: 'Document', value: 'Document' },
  { name: 'Capture', value: 'Capture' },
  { name: 'Offer', value: 'Offer' },
  { name: 'Release', value: 'Release' },
  { name: 'Arrest', value: 'Arrest' },
  { name: 'Give', value: 'Give' },
  { name: 'Send', value: 'Send' },
  { name: 'Assess', value: 'Assess' },
  { name: 'Discover', value: 'Discover' },
  { name: 'Link', value: 'Link' },
  { name: 'Show', value: 'Show' },
  { name: 'Allow', value: 'Allow' },
  { name: 'Distribute', value: 'Distribute' },
  { name: 'Monitor', value: 'Monitor' },
  { name: 'Employ', value: 'Employ' },
  { name: 'Participate', value: 'Participate' },
  { name: 'Accuse', value: 'Accuse' },
  { name: 'Disrupt', value: 'Disrupt' },
  { name: 'Hack', value: 'Hack' },
  { name: 'Publish', value: 'Publish' },
  { name: 'Recommend', value: 'Recommend' },
  { name: 'Get', value: 'Get' },
  { name: 'Notify', value: 'Notify' },
  { name: 'Reveal', value: 'Reveal' },
  { name: 'Mention', value: 'Mention' },
  { name: 'Accused', value: 'Accused' },
  { name: 'Claim', value: 'Claim' },
  { name: 'Impersonate', value: 'Impersonate' },
  { name: 'Sell', value: 'Sell' },
  { name: 'Contact', value: 'Contact' },
  { name: 'Disable', value: 'Disable' },
  { name: 'Open', value: 'Open' },
  { name: 'Prompt', value: 'Prompt' },
  { name: 'Spoof', value: 'Spoof' },
  { name: 'Charge', value: 'Charge' },
  { name: 'Join', value: 'Join' },
  { name: 'Start', value: 'Start' },
  { name: 'Block', value: 'Block' },
].map((field, index) => ({
  ...field,
  color: getRandomColor(index),
}));

const RelationsChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const activeBaseId = useSelector((state) => state.config.activeBase.base);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // On utilise ici des refs pour pouvoir les mettre à jour
  // depuis les widgets "enfants" sans re-render tout le
  // dashboard
  const relationsLinksFilterValue = useRef([relationsLinksFields[0].value]);
  const relationsNodesFilterValue = useRef(_.map(relationsNodesFields, 'value'));

  const buildNodesTypesAdditionalQuery = (takeAllTypes) => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    let nodesTypesToConsider = relationsNodesFilterValue.current;
    if (takeAllTypes) {
      nodesTypesToConsider = relationsNodesFields.map((relationNode) => relationNode.value);
    }
    _.forEach(
      nodesTypesToConsider,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  const handleRefreshRelationChart = (nodesTypesValues, linksTypesValues) => {
    if (_.isEmpty(nodesTypesValues) || _.isEmpty(linksTypesValues)) {
      snackActions.warning(t('dashboard.widget.at_least_one_link_or_node'));
      return;
    }
    relationsNodesFilterValue.current = nodesTypesValues;
    relationsLinksFilterValue.current = linksTypesValues;
    const joinedTypesValue = linksTypesValues.join(',');

    const params = {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      relations: joinedTypesValue,
      aggregates: [joinedTypesValue],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [joinedTypesValue],
      additionalQuery: buildNodesTypesAdditionalQuery(),
      type: 'networkgraph',
    };
    const keepWidgets = true;
    dispatch(fetchWidgets(strategy, {
      relations: params,
    }, keepWidgets));
  };

  const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');
  const widgets = {
    relationsSources: {
      facets: 'QES_Relation_Source_Type.verbatim',
      aggregates: ['agg_QES_Relation_Source_Type.verbatim'],
      type: 'pie',
    },
    categories: {
      facets: 'QES_Relation_Category.verbatim',
      aggregates: ['agg_QES_Relation_Category.verbatim'],
      type: 'treemap',
    },
    relationsDestinations: {
      facets: 'QES_Relation_Destination_Type.verbatim',
      aggregates: ['agg_QES_Relation_Destination_Type.verbatim'],
      type: 'pie',
    },
    datatablesConcepts: {
      facets: _.keys(DATATABLE_CONCEPTS).join(';'),
      aggregates: _.keys(DATATABLE_CONCEPTS).map((c) => `agg_${c}`),
      seriesNames: _.map(DATATABLE_CONCEPTS, 'title'),
      facetmax: 50,
      facetmax2: 3,
      splitPerSerie: true,
      type: 'datatable',
    },
    relations: {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      relations: relationsLinksFilterValueJoined,
      aggregates: [relationsLinksFilterValueJoined],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [relationsLinksFilterValueJoined],
      additionalQuery: buildNodesTypesAdditionalQuery(),
      type: 'networkgraph',
    },
  };

  const handleExportWidget = (widgetId, facet) => {
    const keepWidgets = true;
    const params = widgets[widgetId];
    if (facet) {
      params.facets = facet;
    }
    params.facetmax = 100000;
    params.exportWidget = true;
    dispatch(fetchWidgets(strategy, {
      widgetId: params,
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      dispatch(fetchWidgets(strategy, widgets));
    }

  // On ne met pas cooccurrencesNodesFilterValue ni relationsLinksFilterValue dans les dépendances,
  // pour éviter de refresh tout le dashboard quand on change juste le type
  // des éléments affichés (refresh gérés dans handleChangeCooccurrenceValue
  // et handleChangeRelationFieldValue).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy, dispatch]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
      pb="30px"
    >
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="relationsSources">
          {({ series: relationsSources, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('relationsSources')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.sortBy(_.get(relationsSources, '[0].data', []), 'name'),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 1032000598, strategie: event.point.name },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: t('dashboard.widget.part_left_type'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="categories">
          {({ series: categoriesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Treemap}
              handleExportWidget={() => handleExportWidget('categories')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    treemap: {
                      animation: false,
                      colorByPoint: true,
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 1032000597, strategie: event.point.name },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  series: categoriesSerie,
                  title: {
                    text: t('dashboard.widget.relation_type'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={4}>
        <WidgetContainer widgetId="relationsDestinations">
          {({ series: relationsDestinations, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              handleExportWidget={() => handleExportWidget('relationsDestinations')}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: _.sortBy(_.get(relationsDestinations, '[0].data', []), 'name'),
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 1032000599, strategie: event.point.name },
                            ], (event.ctrlKey || event.metaKey)));
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: t('dashboard.widget.part_right_type'),
                    align: 'left',
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {
        _.entries(DATATABLE_CONCEPTS).map(([fieldName, fieldDefinition]) => (
          <Grid item xs={12} sm={4} key={`datatablesConcepts${fieldName}`}>
            <WidgetContainer widgetId={`datatablesConcepts_${fieldDefinition.title}`}>
              {({ series: datatableSerie, height }) => (
                <DashboardWidget
                  component={Datatable}
                  handleExportWidget={() => handleExportWidget('datatablesConcepts', fieldName)}
                  componentProps={{
                    title: { text: fieldDefinition.title, align: 'left' },
                    columns: [
                      { name: fieldDefinition.columnName },
                      { name: t('dashboard.widget.count'), align: 'right' },
                    ],
                    onRowClick: (event, rowData) => dispatch(refineAddFacetsValues([
                      { champ: fieldDefinition.field, strategie: rowData[0] },
                    ], (event.ctrlKey || event.metaKey))),
                    data: datatableSerie.data,
                  }}
                  height={height}
                />
              )}
            </WidgetContainer>
          </Grid>
        ))
      }

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="relations" height={700}>
          {({ series: relations, height, additionalData }) => {
            relationsLinksFields = relationsLinksFields.map(
              (relationsLinksField) => ({
                ...relationsLinksField,
                nbLinks: _.get(additionalData, `linkCounts.${relationsLinksField.value}`, 0),
              }),
            );
            relationsNodesFields = relationsNodesFields.map(
              (relationsNodesField) => ({
                ...relationsNodesField,
                nbNodes: _.get(additionalData, `nodeCounts.${relationsNodesField.value}`, 0),
              }),
            );
            return (
              <DashboardWidget
                height={height}
                component={NetworkGraph}
                handleExportWidget={() => handleExportWidget('relations')}
                componentProps={{
                  additionalData,
                  withExploration: true,
                  linksFilterValue: relationsLinksFilterValue.current,
                  linksFilterItems: _.orderBy(
                    relationsLinksFields,
                    ['nbLinks', (item) => relationsLinksFilterValue.current.indexOf(item.value) > -1, 'name'],
                    ['desc', 'desc', 'asc'],
                  ),
                  nodesFilterValue: relationsNodesFilterValue.current,
                  nodesFilterItems: _.orderBy(
                    relationsNodesFields,
                    ['nbNodes', 'name'],
                    ['desc', 'asc'],
                  ),
                  handleRefreshChart: handleRefreshRelationChart,
                  handleLinkClick: (event, fromNode, toNode, link) => {
                    const {
                      idField: fromIdField,
                    } = _.find(relationsNodesFields, { value: fromNode.options.group });
                    const {
                      idField: toIdField,
                    } = _.find(relationsNodesFields, { value: toNode.options.group });
                    const facetsValues = [{
                      champ: fromIdField,
                      strategie: fromNode.id,
                    }];
                    // Si jamais on clique sur un lien découvert par l'exploration,
                    // on va repartir d'une recherche vide, donc on refresh tout
                    const refreshFields = {
                      [`F_${fromIdField}`]: `"${_.trim(fromNode.id, ' "')}"`,
                    };
                    if (fromIdField === toIdField) {
                      // Si jamais l'ID de champ est le même pour les deux noeuds,
                      // on colle les deux valeurs dans un même tableau, qui sera
                      // découpé convenablement au moment du fetch des résultats
                      facetsValues[0].strategie = [fromNode.id, toNode.id];
                      refreshFields[`F_${fromIdField}`] = (
                        `"${_.trim(fromNode.id, ' "')}" AND "${_.trim(toNode.id, ' "')}"`
                      );
                    } else {
                      // A l'inverse, si les ID de champs sont différents, on
                      // on ajoute une seconde "facetValue" avec les éléments
                      // du second noeud
                      facetsValues.push({
                        champ: toIdField,
                        strategie: toNode.id,
                      });
                      refreshFields[`F_${toIdField}`] = `"${_.trim(toNode.id, ' "')}"`;
                    }
                    if (link.options.iteration) {
                      if (link.options.relatedLinks) {
                        relationsLinksFilterValue.current = [...new Set(
                          _.concat(
                            relationsLinksFilterValue.current,
                            _.map(link.options.relatedLinks, 'type'),
                          ),
                        )];
                      } else if (!relationsLinksFilterValue.current.includes(link.options.type)) {
                        relationsLinksFilterValue.current.push(link.options.type);
                      }
                      let additionnalFetchParams = {
                        refreshForm: true,
                        clearSelection: true,
                        clearResults: true,
                      };
                      if (event.ctrlKey || event.metaKey) {
                        additionnalFetchParams = {
                          quickResultsScope: true,
                        };
                      } else {
                        dispatch(setDashboardLoading());
                      }
                      dispatch(fetchResults({
                        bodyItems: {
                          premier: 1,
                          dernier: 50,
                          base: activeBaseId,
                          champs: refreshFields,
                        },
                        ...additionnalFetchParams,
                      }));
                    } else {
                      dispatch(refineAddFacetsValues(facetsValues, (event.ctrlKey || event.metaKey)));
                    }
                  },
                }}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    plotOptions: {
                      series: {
                        point: {
                          events: {
                            click: (event) => {
                              const { idField } = _.find(relationsNodesFields, { value: event.point.options.group });
                              if (idField) {
                                if (event.shiftKey) {
                                  const joinedTypesValue = relationsLinksFields.map((field) => field.value).join(',');
                                  const params = {
                                    facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
                                    relations: joinedTypesValue,
                                    aggregates: [joinedTypesValue],
                                    facetmax: 200,
                                    facetmax2: 5,
                                    mindoccount: 1,
                                    seriesNames: [joinedTypesValue],
                                    additionalQuery: buildNodesTypesAdditionalQuery(),
                                    type: 'networkgraph',
                                  };
                                  const keepWidgets = true;
                                  const keepWidgetData = true;
                                  dispatch(fetchWidgets(
                                    `QES_Relation_Source_Text.verbatim:"${_.trim(event.point.name, ' "')}"`,
                                    { relations: params },
                                    keepWidgets,
                                    null,
                                    keepWidgetData,
                                    event.point.name,
                                  ));
                                } else if (event.point.options.iteration) {
                                  let additionnalFetchParams = {
                                    refreshForm: true,
                                    clearSelection: true,
                                    clearResults: true,
                                  };
                                  if (event.ctrlKey || event.metaKey) {
                                    additionnalFetchParams = {
                                      quickResultsScope: true,
                                    };
                                  } else {
                                    dispatch(setDashboardLoading());
                                  }
                                  dispatch(fetchResults({
                                    bodyItems: {
                                      premier: 1,
                                      dernier: 50,
                                      base: activeBaseId,
                                      champs: {
                                        [`F_${idField}`]: `"${_.trim(event.point.name, ' "')}"`,
                                      },
                                    },
                                    ...additionnalFetchParams,
                                  }));
                                } else {
                                  dispatch(refineAddFacetsValues([
                                    {
                                      champ: idField,
                                      strategie: event.point.name,
                                    },
                                  ], (event.ctrlKey || event.metaKey)));
                                }
                              }
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      nodes: _.get(relations, '[0].nodes', []).map(
                        (node) => ({
                          ...node,
                          color: _.find(relationsNodesFields, { value: node.group })?.color,
                        }),
                      ),
                      data: _.get(relations, '[0].data', []).map(
                        (link) => ({
                          ...link,
                          color: link.color || _.find(relationsLinksFields, { value: link.type })?.color || '#c5c5c5',
                        }),
                      ),
                    }],
                    title: {
                      text: t('dashboard.widget.relations_graph'),
                      align: 'left',
                    },
                  },
                }}
              />
            );
          }}
        </WidgetContainer>
      </Grid>

    </Grid>
  );
};

export default RelationsChartsContainer;
