import React from 'react';
import { Box } from '@mui/material';
import { CONSTANTS } from 'generic/core/constants';
import FormLoginContainer from 'generic/containers/auth/FormLoginContainer';
import FormResetPasswordConfrimationContainer from 'generic/containers/auth/FormResetPasswordConfrimationContainer';

import { overrideRessource } from 'generic/utils/utils';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const { PROJECT_NAME } = CONSTANTS;

const logo = overrideRessource('img/login-logo.png', true);

const AuthenticationLanding = () => {
  const { path } = useRouteMatch();
  const theme = useTheme();

  return (
    <Box
      color="primary.contrastText"
      bgcolor="primary.main"
      height="100vh"
      display="flex"
      alignItems="stretch"
      justifyContent="center"
      flexGrow="1"
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        color="primary.light"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: 'auto', md: '50%' },
          borderRight: { xs: 'none', md: '2px solid' },
        }}
      >
        <Box
          component="img"
          src={logo}
          alt={PROJECT_NAME}
          sx={{
            marginBottom: { xs: '2em', md: '0' },
            ...theme?.custom?.login?.logo || '',
          }}
        />
      </Box>
      <Box
        padding="0 10%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: 'auto', md: '50%' },
        }}
      >
        <Switch>

          <Route path={`${path}/reset-password-confirmation`}>
            <FormResetPasswordConfrimationContainer />
          </Route>

          <Route>
            <FormLoginContainer />
          </Route>
        </Switch>

      </Box>
    </Box>
  );
};

export default AuthenticationLanding;
