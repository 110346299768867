/**
 * QES est rempli de constantes obsolètes, de doublons, de code mort, ...
 * Il serait idiot de copier TOUTES les constantes issue de l'historique QES
 * On ajoutera au fil de l'eau les constantes nécessaires.
 * Pour simplifier l'accès aux informations, merci de préciser en commentaire
 * au dessus de la constante les requêtes SQL qui ont permis de retrouver l'info
 */

import _ from 'lodash';
import { CUSTOM_PATH } from 'generic/core/constants';

const imgExtensions = '.jpg, .jpeg, .gif, .png, .tiff';
const officeExtensions = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .txt, .csv';

const dateIntervalComparator = {
  less_than: 1,
  equal: 2,
  more_than: 3,
  between: 4,
  unknown: 1000,
};

const openUserStatus = 1;

let constants = {
  API_ENTRY_POINT: process.env.REACT_APP_API_ENTRY_POINT,

  // en dur dans le code de qes
  FACET_POSITION_MAPPING: {
    left: 1,
    right: 2,
    undefined: 1000,
  },

  // requête par défaut pour les recherches globales sur la base (dashboard, entrée sur la page de résultat en direct, ...)
  DEFAULT_SEARCH_PER_BASE: {
    default: {
      DATE_141_comp: dateIntervalComparator.less_than,
      DATE_141_from: '01/01/2100',
    },
  },

  // SELECT * FROM Format_saisie;
  INPUT_FORMAT: {
    date_with_comparator: 7,
    number: 13,
    text: 19,
    dropdown_list: 20,
    autocomplete_column_direct_regex: 38,
  },

  // SELECT * FROM Format_document;
  DOCUMENT_FORMAT: {
    COMPLET: 1,
    VEILLE: 3,
    RESUME: 4,
    TITRE: 5,
    FIL: 9,
    NOTIFICATION_AU_SERVEUR: 10,
    NON_PRECISE: 1000,
  },

  // SELECT * FROM Etat_document;
  ETAT_DOCUMENT: {
    NOUVEAU: 1,
    DOUBLON: 2,
    REJETE: 3,
    COLLECTE: 4,
    ERREUR: 5,
    VALIDE: 50,
    PUBLIE: 100,
    SUPPRIMER: 203,
    LIER: 204,
    FUSIONNER: 205,
    COMMENTER: 206,
    DUPLIQUER: 207,
    RANKING: 220,
    DEDOUBLONNER: 230,
    FAVORI: 240,
    NON_PRECISE: 1000,
    A_COMPLETER: 1001,
  },

  FILE_UPLOAD_FORMATS: {
    csv: {
      backOfficeType: '1',
      fileExtensions: '.csv, .txt',
    },
    refman: {
      backOfficeType: '2',
      fileExtensions: '.xml, .ris, .txt',
    },
    cart: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    newsletter: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    trombi: {
      backOfficeType: '4',
      fileExtensions: imgExtensions,
    },
    plateform: {
      backOfficeType: '3',
      fileExtensions: imgExtensions,
    },
    docToUpload: {
      fileExtensions: `${imgExtensions}, ${officeExtensions}`,
    },
  },

  // SELECT * FROM Civilite;
  CIVILITY: {
    mrs: 1,
    miss: 2,
    mr: 3,
    unknown: 1000,
  },

  // SELECT * FROM Etat_veille
  // SELECT * FROM Type_veille
  // On ne garde que les typologies intéressantes pour le front
  ALERT: {
    STATUSES: {
      DEFAULT_ACTIVE: 2,
      DEFAULT_INACTIVE: 5,
      ACTIVE: [1, 2],
      INACTIVE: [5],
    },
    TYPES: {
      ALERT: 1,
      REQUEST: 6,
    },
  },

  OPENED_USER_REQUEST: '1',
  CLOSED_USER_REQUEST: '2,3,4',
  OPEN_USER_STATUS: openUserStatus,
  CLOSED_USER_STATUS: 3,

  TYPE_PJ_WITHOUT: '1',

  // SELECT * FROM Raffinage;
  REFINING_STRATEGY: {
    adjacency: 1,
    semantics: 2,
    exact_expression: 10,
    as_is: 1000,
  },

  // SELECT * FROM Comparateur;
  DATE_INTERVAL_COMPARATOR: dateIntervalComparator,

  // SELECT * FROM Visibilite_panier;
  CART_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },
  NEWSLETTER_VISIBILITY: {
    private: 1000,
    all: 3,
    collaborative: 1,
    company: 2,
    profile: 4,
  },

  DEFAULT_USER: {
    civilite: 1,
    email: '',
    logon: '',
    utilisateur: null,
    nom: '',
    prenom: '',
    etat_utilisateur: openUserStatus,
  },

  CRITERES: {
    AND: 1,
    OR: 2,
    NOT: 3,
  },

  /* ---------- PARAMETRES ERGONOMIQUES A OVERRIDER ---------- */
  // Option pour modifier le comportement du clic sur un titre d'article dans le search :
  // 'newTab' > ouvre l'info dans un nouvel onglet (pas de bouton pour ouvrir dans le complet)
  // 'complete' > ouvre le complet
  CLICK_ON_DOCS_TITLE_BEHAVIOR: 'complete',
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: true,
  // Taille en "unité" de Grid MUI pour les colonnes des facettes
  MAX_FACET_COLUMN_WIDTH: 2,
  // Nombre de facettes affichées quand on est en mode "shrinked"
  MAX_FACET_DISPLAYED_WHEN_SHRINKED: 10,
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customConstants } = require(`custom/${CUSTOM_PATH}/core/qes/constants`);
    constants = _.merge(constants, customConstants);
  } catch (err) {
    console.info('Pas de fichier custom de constantes QES');
  }
}

const QES_CONSTANTS = constants;
export default QES_CONSTANTS;
